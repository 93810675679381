import wurd from 'wurd-react';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import * as actions from '../actions';
import store from 'store';
import useForm from 'utils/useForm';
import Paper from 'components/paper';
import Title from 'components/title';
import Field from 'components/field';
import Button from 'components/button';
import ErrMsg from 'components/err-msg';
import TermsCheck from 'components/terms-check';
import GoogleButton from 'components/google-button';
import CustomFields from 'plugins/custom-fields/fields';

const cms = wurd.block('signup');

const Container = styled('div')({
  maxWidth: 460,
  padding: 15,
  margin: 'auto',
});

export default function Signup() {
  const { settings } = store.get();
  const navigate = useNavigate();
  const { submit, fieldProps, loading, err } = useForm({
    cms,
    onSubmit: data => actions.user.signup(data),
    onSuccess: () => {
      navigate('/');
    }
  });

  return (
    <Container>
      <Title cms={cms.block('title')} className="my-5" />

      <Paper padded>
        <form onSubmit={submit}>
          <div className="d-flex flex-column gap-2">
            <Field {...fieldProps('firstName')} required />
            <Field {...fieldProps('lastName')} required />
            <Field {...fieldProps('email')} required type="email" />
            <Field {...fieldProps('password')} required type="password" />

            <CustomFields
              fieldProps={fieldProps}
              fieldSettings={settings.userCustomFields}
              mode="create"
            />

            <TermsCheck cms={cms} className="mt-2" />
          </div>

          {err && <ErrMsg err={err} className="mt-4 py-1" />}

          <Button type="submit" className="w-100 text-center mt-5 mb-2" loading={loading}><cms.Text id="submitText" /></Button>
        </form>

        <GoogleButton signup redirectUrl={location.origin + '/account'} />
      </Paper>
    </Container>
  )
}
