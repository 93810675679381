import { BrowserRouter, Routes, Route, Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import wurd from 'wurd-react';
import Loader from './components/loader';
import * as actions from './actions';
import Login from './pages/login';
import Signup from './pages/signup';
import ForgotPassord from './pages/forgot-password';
import ResetPassword from './pages/reset-password';
import MyUnits from './pages/my-units';
import MyItems from './pages/my-items';
import SiteList from './pages/site-list';
import SiteDetail from './pages/site-detail';
import UnitTypeDetail from './pages/unit-type-detail';
import UnitOrder from './pages/unit-order';
import ValetOrder from './pages/valet-order';
import ValetOrderEdit from './pages/valet-order-edit';
import ValetOrderSchedule from './pages/valet-order-schedule';
import Profile from './pages/profile';
import Billing from './pages/billing';
import InvoiceList from './pages/invoice-list';
import InvoiceDetail from './pages/invoice-detail';
import store from './store';
import HeaderBar from './components/header';
import { useEffect } from 'react';
import Footer from './components/footer';
import Empty from './components/empty';
import DynamicLoader from 'components/dynamic-loader';
import analytics from './utils/analytics';


function Index() {
  const { user, settings } = store.get();
  const navigate = useNavigate();
  useEffect(() => {
    const sp = new URLSearchParams(location.search);
    if(sp.get('_r') === 'g') {
      sp.delete('_r');
      if (sp.has('signup')) {
        analytics.signedUp(user, 'Google');
        sp.delete('signup');
      } else {
        analytics.identify(user, 'Google');
      }
    }

    const search = sp.toString() ? `?${sp}` : '';
    const selfStorage = settings.sites?.length;
    const valetStorage = settings.items?.length;
    if (user) {
      if (user.units.length > 0 && selfStorage) return navigate('/account/units' + search, { replace: true });
      if (user.items.length > 0 && valetStorage) return navigate('/account/items' + search, { replace: true });
    }
    if (selfStorage) return navigate('/sites' + search, { replace: true });
    if (valetStorage) return navigate('/valet' + search, { replace: true });

    navigate(location.pathname + search, { replace: true });
  }, [navigate]);

  if (user !== undefined) {
    return <Empty cms={wurd.block('common.notSetup')} className="container mb-2" />;
  }

  return <Loader />;
}

function Layout({ children }) {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <HeaderBar />

      <main style={{ flex: 1 }}>{children}</main>

      <Footer />
    </div>
  );
}

// TODO a notFound page?
export default function Router() {
  const { isLoading } = useQuery('user', () => actions.user.fetch({ initial: true }));
  const { settings, user } = store.get();

  const loggedIn = element => isLoading ? <Loader /> : !user ? <Login onSuccess={null} /> : element;

  const selfStorage = !!settings.sites?.length;
  const valetStorage = !!settings.items?.length;

  return (
    <BrowserRouter basename={process.env.REACT_APP_BASE_PATH || '/'}>
      <Layout>
        <Routes>
          <Route path="/account/login" element={<Login />} />
          <Route path="/account/signup" element={<Signup />} />
          <Route path="/account/forgot-password" element={<ForgotPassord />} />
          <Route path="/account/reset-password" element={<ResetPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />

          {selfStorage && <Route path="/account/units" element={<MyUnits />} />}
          {selfStorage && <Route path="/sites/:site/:type/:date" element={<UnitOrder />} />}
          {selfStorage && <Route path="/sites/:site/:type" element={<UnitTypeDetail />} />}
          {selfStorage && <Route path="/sites/:site" element={<SiteDetail />} />}
          {selfStorage && <Route path="/sites" element={<SiteList />} />}

          {valetStorage && <Route path="/account/items" element={<MyItems />} />}
          {valetStorage && <Route path="/account/orders/:id" element={<ValetOrderEdit />} />}
          {valetStorage && <Route path="/account/collect/:items" element={<ValetOrderSchedule />} />}
          {valetStorage && <Route path="/account/deliver/:items" element={<ValetOrderSchedule />} />}
          {valetStorage && <Route path="/valet" element={<ValetOrder />} />}

          <Route path="/account/profile" element={loggedIn(<Profile />)} />
          <Route path="/account/billing" element={loggedIn(<Billing />)} />
          <Route path="/account/invoices/:sid" element={loggedIn(<InvoiceDetail />)} />
          <Route path="/account/invoices" element={loggedIn(<InvoiceList />)} />
          {process.env.REACT_APP_DEV_STORIES && (
            <Route path="/account/stories/*" element={<Stories />} />
          )}
          <Route path="*" element={isLoading ? <Loader /> : <Index />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

function Stories(props) {
  const params = useParams();

  const Component = DynamicLoader(() => import('./components/' + params['*'] + '.stories.js'));
  return <Component />;
}