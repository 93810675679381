import api from '../utils/api';
import errHandler from './_err-handler';


export function fetch(search) {
  return api.get(`/v1/sites${search ? `?${new URLSearchParams(search)}` : ''}`)
    .catch(errHandler);
}

export function get(id, params) {
  return api.get(`/v1/sites/${id}${params ? `?${new URLSearchParams(params)}` : ''}`)
    .catch(errHandler);
}
