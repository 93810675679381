import { useNavigate } from 'react-router-dom';
import wurd from 'wurd-react';
import moment from 'moment';
import styled from '@emotion/styled';
import * as actions from 'actions';
import store from 'store';
import { isCollection, isDelivery, canReschedule } from 'utils/valet';
import ItemList from 'components/item/list';

const cms = wurd.block('my-items.order');

const AccordionButton = styled.button({
  padding: '1.25rem 3.5rem 1.25rem 1.5rem',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  gap: '.5rem',
  '::after': {
    fontSize: '2em',
    color: 'var(--brand)',
    position: 'absolute',
    right: '1.25rem',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  'strong': {
    fontSize: '1.25rem',
    fontWeight: 600,
  },
  'small': {
    fontSize: '.75rem',
  },
  ':hover': {
    zIndex: 'auto'
  }
});

function cancel(order) {
  const { settings } = store.get();
  if (!canReschedule(order) && process.env.REACT_APP_DEV_FORCE_CANCEL !== 'true') {
    return window.alert(cms.text('actions.cancel.tooLate'));
  }

  const confirmed = window.confirm(cms.text('actions.cancel.confirm', {
    sid: order.sid,
    date: moment(order.date).format(settings.dateFormats.long),
    timeslot: order.timeslot.replace(/(\d\d)(\d\d)/g, '$1:$2'),
  }));
  if (!confirmed) return;

  return actions.valetOrders.cancelValetOrder(order.id)
    .catch(err => window.alert(err.message));
}


export function Title(order) {
  const { settings } = store.get();
  const collectLater = isDelivery(order) && isCollection(order) && order.collect === 'later';
  const numEmpties = Object.values(order.boxCounts || {}).reduce((t, count) => t + Number(count), 0);

  const desc = [
    numEmpties > 0 && cms.text('empties', { num: numEmpties }),
    order.collectItems?.length > 0 && cms.text('collect', { num: order.collectItems.length }),
    order.deliverItems?.length > 0 && cms.text('deliver', { num: order.deliverItems.length }),
  ]
    .filter(Boolean)
    .join(' · ');

  return (
    <>
      <span>
        <strong>
          <time dateTime={order.date}>{moment(order.date).format(settings.dateFormats.long)}</time> {order.timeslot.replace(/\d{4}/g, s => s.slice(0, 2) + ':' + s.slice(2, 4))}
        </strong>
        {collectLater && (
          <>
            <cms.Text id="collectLater" type="small" />
            <strong>
              <time dateTime={order.collectDate}>{moment(order.collectDate).format(settings.dateFormats.long)}</time> {order.collectTimeslot?.replace(/\d{4}/g, s => s.slice(0, 2) + ':' + s.slice(2, 4))}
            </strong>
          </>
        )}
      </span>
      <cms.Object type="small" keys="empties,collect,deliver">{desc}</cms.Object>
      {order.sid && <small className="user-select-all d-none d-lg-inline-block position-absolute top-50 end-0 translate-middle-y p-2 me-5 text-muted">{order.sid.toUpperCase()}</small>}
    </>
  );
}


export default function ValetUpcomingOrders({
  user,
  toggleModal,
}) {
  const navigate = useNavigate();
  const valetOrders = [...user?.valetOrders || []]
    .sort((a, b) => a.date.localeCompare(b.date));
  const itemsById = Object.fromEntries(user?.items.map(item => [item.id, item]) || []);

  function edit(order) {
    if (!canReschedule(order)) {
      return window.alert(cms.text('actions.edit.tooLate'));
    }
    navigate(`/account/orders/${order.id}`);
  }

  return (
    <div className="accordion accordion-flush mb-5" id="upcomingOrdersAccordion">
      {valetOrders.map(order => {
        const itemIds = [...order.deliverItems || [], ...order.collectItems || []];
        const items = itemIds.map(id => itemsById[id]).filter(Boolean);
        const show = window.location.hash === `#order_${order.sid.toUpperCase()}` || new URLSearchParams(window.location.search).get('order') === order.sid.toUpperCase();

        return (
          <div key={order.id} className="accordion-item" id={`order_${order.sid}`}>
            <h2 className="accordion-header" id={`header_${order.id}`}>
              <AccordionButton className={`accordion-button ${show ? '' : 'collapsed'}`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse_${order.id}`} aria-controls={`collapse_${order.id}`}>
                <Title {...order} />
              </AccordionButton>
            </h2>
            <div id={`collapse_${order.id}`} className={`accordion-collapse collapse ${show ? 'show' : ''}`} aria-labelledby={`header_${order.id}`} data-bs-parent="#upcomingOrdersAccordion">
              <div className="accordion-body" style={{ minHeight: 60 }}>
                <cms.Object
                  type="div"
                  id="actions"
                  keys="cancel.btn,cancel.tooLate,cancel.confirm,edit.tooLate,edit.btn"
                  className="d-flex justify-content-end"
                  style={{ margin: '-.5rem -1rem .5rem .5rem' }}
                >
                  <button className="btn btn-sm btn-gray-bg" onClick={() => cancel(order)}>{cms.text('actions.cancel.btn')}</button>
                  <button className="btn btn-sm btn-primary ms-1" onClick={() => edit(order)}>{cms.text('actions.edit.btn')}</button>
                </cms.Object>
                <ItemList items={items} toggleModal={toggleModal} />
                {items.length === 0 && <cms.Markdown id="empty" className="text-muted" />}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
