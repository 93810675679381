import styled from '@emotion/styled';
import wurd from 'wurd-react';
import { getLangText } from 'utils/ui';
import { getMapLink } from 'utils/site';
import useMobile from 'utils/useMobile';
import FeatureList from 'components/feature-list';
import Paper from 'components/paper';
import Markdown from 'components/markdown';


const cms = wurd.block('siteDetail');

const Wrapper = styled.div({
  '.card-img-top': {
    maxHeight: 400,
    objectFit: 'contain',
    padding: '.5rem .5rem 0 .5rem',
  },
});

const MobileWrapper = styled(Paper)({
  display: 'flex',

  '.card-img-top': {
    width: 150,
    aspectRatio: '4 / 3',
    height: 130,
    padding: '.5rem',
  },

  '.card-body': {
    display: 'flex',
    flexDirection: 'column',
    padding: '.5rem',
    userSelect: 'text',
    letterSpacing: 'normal',
    h2: {
      fontSize: '1.125rem',
    },
    ul: {
      lineHeight: '1.25rem',
      flex: 1,
    },
    details: {
      marginTop: '.2rem',
    },
    '.fs-6': {
      fontSize: '.75rem!important'
    }
  }
});


export default function SiteCard({
  site,
  className = '',
  showFeatures,
  showInfos = true,
}) {
  const isMobile = useMobile();
  const allSiteFeatures = [...new Map(site.unitTypes.flatMap(u => u.tags.map(t => [t.id, t]))).values()];

  const content = (
    <>
      <ul className="d-flex mt-1 mt-lg-3 list-unstyled flex-column gap-1">
        {site.phone && (
          <li className="d-none d-lg-block">
            <i className="fal fa-phone-alt fa-fw fs-6 me-2" />
            <a className="text-reset fw-500 fs-6 text-decoration-none" href={`tel:${site.phone.replace(/\s/g, '')}`} target="_blank">{site.phone}</a>
          </li>
        )}
        <li>
          <i className="d-none d-lg-inline-block fal fa-envelope fa-fw fs-6 me-2" />
          <a className="text-reset fw-500 fs-6 text-decoration-none" href={`mailto:${site.email}`} target="_blank">{site.email}</a>
        </li>
        <li className="d-none d-lg-block text-muted">
          <i className="fal fa-map-marker-alt fa-fw fs-6 me-2" />
          <a className="fs-6 text-reset text-decoration-none" href={getMapLink(site)} target="_blank">{getLangText(site.address)}</a>
        </li>
      </ul>

      {getLangText(site.hours) && (
        <div className="mt-2 mt-lg-4">
          <h4 className="fs-6 fw-600"><cms.Text id="hours" /></h4>
          <Markdown className="card-text markdown mt-3 text-muted fs-6">{getLangText(site.hours)}</Markdown>
        </div>
      )}

      {showFeatures && allSiteFeatures.length > 0 && (
        <div className="mt-3">
          <h4 className="fs-6 fw-600"><cms.Text id="features" /></h4>
          <FeatureList className="mt-2" tags={allSiteFeatures} />
        </div>
      )}

      {showInfos && <Markdown className="card-text markdown text-muted mt-3 fs-6 fw-light">{getLangText(site.info)}</Markdown>}
    </>
  );

  // todo fix invalid DOM: we have block elements like h2, p, ul, div inside a button (.accordion.button)
  if (isMobile) {
    return (
      <MobileWrapper>
        <img src={site.image} className={`card-img-top ${site.imageFit || ''}`} alt={site.code} />

        <div className="card-body flex-1 fs-7">
          <h2>{getLangText(site.title)}</h2>
          <p className="fw-light text-muted mb-0 mb-lg-3">{getLangText(site.subtitle)}</p>

          <ul className="list-unstyled mt-1">
            {site.phone && (
              <li>
                <a className="text-reset fw-500 text-decoration-none" href={`tel:${site.phone.replace(/\s/g, '')}`} target="_blank">{site.phone}</a>
              </li>
            )}
            <li>
              <a className="text-reset text-decoration-none fw-normal" href={getMapLink(site)} target="_blank">{getLangText(site.address)}</a>
            </li>
          </ul>

          <details>
            <summary className="btn-link text-decoration-none fs-7 fw-600"><cms.Text id="more" /></summary>
            <div className="mt-1">
              {content}
            </div>
          </details>
        </div>
      </MobileWrapper>
    );
  }

  return (
    <Wrapper className={`card ${className}`}>
      <img src={site.image} className={`card-img-top ${site.imageFit || ''}`} alt={site.code} />

      <div className="card-body flex-1">
        <h2>{getLangText(site.title)}</h2>
        <p className="fw-light text-muted fs-6 mb-0 mb-lg-3">{getLangText(site.subtitle)}</p>

        <div className="d-none d-lg-block">
          {content}
        </div>


      </div>
    </Wrapper>
  );
}
