import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import wurd from 'wurd-react';
import Split from 'components/split-page';
import Progress from 'components/unit-progress';
import UnitTypeCard from 'components/unit-type-detail/unit-type-card';
import DatePicker from 'components/unit-type-detail/date-picker';
import Loader from 'components/loader';
import * as actions from 'actions';


const cms = wurd.block('unitDetail');


export default function UnitDetail() {
  const { site: siteCode, type } = useParams();

  const { isLoading, error, data: site } = useQuery('site-' + siteCode, () => actions.sites.get(siteCode, { include: 'unitTypes,minmaxBookingDates' }));

  const unitType = site?.unitTypes.find(u => u.code === type);

  if (!unitType) return <h1><cms.Text id="notFound" /></h1>;

  return (
    <>
      <Progress site={site} unitType={unitType} />

      <Split className="mb-2">
        <div className="flex-1"><UnitTypeCard site={site} unitType={unitType} /></div>
        <div className="flex-1 mt-lg-2"><DatePicker site={site} unitType={unitType} /></div>
      </Split>
    </>
  );
}
