import wurd from 'wurd-react';
import styled from '@emotion/styled';
import { Link, useNavigate } from 'react-router-dom';
import Modal from 'bootstrap/js/dist/modal';
import * as actions from 'actions';
import useNotify from 'utils/useNotify';
import useForm from 'utils/useForm';
import Title from 'components/title';
import Paper from 'components/paper';
import Field from 'components/field';
import Button from 'components/button';
import ErrMsg from 'components/err-msg';

const cms = wurd.block('resetPassword');

const SuccessInfo = styled.div({
  p: { margin: 0 },
});

const Container = styled('div')({
  maxWidth: 460,
  padding: 15,
  margin: 'auto',
});

export default function ResetPassword() {
  const navigate = useNavigate();
  const notify = useNotify();
  const { submit, fieldProps, isLoading, err } = useForm({
    cms,
    onSubmit: data => actions.user.resetPassword({ ...data, token: new URLSearchParams(location.search).get('token') })
      .then(() => actions.user.signin(data)),
    onSuccess: () => {
      navigate('/');
      notify(<cms.Text id="submitted.title" />);
    },
  });

  return (
    <Container>
      <Title cms={cms.block('title')} className="my-5" />

      <Paper padded>
        <form onSubmit={submit}>
          <div className="d-flex flex-column gap-2">
            <Field
              {...fieldProps('email')}
              type="email"
              required
              autoComplete="username"
              autoFocus
            />
            <Field
              {...fieldProps('password')}
              required
              type="password"
            />
          </div>

          {err && <ErrMsg err={err} className="mt-4 py-1" />}

          <Button type="submit" className="w-100 text-center mt-5 mb-2" loading={isLoading}><cms.Text id="submitText" /></Button>
        </form>
      </Paper>
    </Container>
  )
}
