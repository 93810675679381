import store from '../store';


/**
 * Detects and sets the current language
 */
export default async function init(settings = {}) {
  const availableLanguages = settings.languages || ['en'];
  const defaultLang = settings.defaultLang /* deprecated */ || availableLanguages[0];

  let lang = new URLSearchParams(window.location.search).get('lang') // First get from query string
    || window.localStorage.getItem('lang') // Then localStorage
    || (navigator.languages ? navigator.languages[0] : navigator.language || navigator.userLanguage); // Fall back to browser setting

  const _baseLang = lang.split('-', 1)[0];
  const baseLang = { nb: 'no' }[_baseLang] || _baseLang; // convert 'nb' to 'no' as we use 'no' in our availableLanguages settings for Norsk

  if (!availableLanguages.includes(lang)) { // Ensure in list of supported languages, fallback to non-region specific language or default else
    lang = availableLanguages.find(l => l === baseLang) || defaultLang;
  }

  store.set({ lang });

  // Update moment locale for calendars etc.
  // This is to make webpack not bundle all moment locales, we could also do import(`moment/locale/${lang}`) and use moment-locales-webpack-plugin but more complex to customize build with react-scripts
  switch (baseLang) {
    case 'da': return import('moment/locale/da');
    case 'de': return import('moment/locale/de');
    case 'es': return import('moment/locale/es');
    case 'fi': return import('moment/locale/fi');
    case 'fr': return import('moment/locale/fr');
    case 'it': return import('moment/locale/it');
    case 'he': return import('moment/locale/he');
    case 'ja': return import('moment/locale/ja');
    case 'ko': return import('moment/locale/ko');
    case 'lt': return import('moment/locale/lt');
    case 'nl': return import('moment/locale/nl');
    case 'no': return import('moment/locale/nb'); // no 'no'
    case 'pt': return import('moment/locale/pt');
    case 'ro': return import('moment/locale/ro');
    case 'sk': return import('moment/locale/sk');
    case 'sl': return import('moment/locale/sl');
    case 'sv': return import('moment/locale/sv');
    case 'th': return import('moment/locale/th');
    case 'zh': return import('moment/locale/zh-cn'); // There is no 'base' zh in moment
    default: return;
  }
}
