import { useEffect, useState, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import wurd from 'wurd-react';
import { useQuery } from 'react-query';
import Modal from 'react-bootstrap/Modal';
import { getLangText } from 'utils/ui';
import useSearchData from 'utils/useSearchData';
import Button from 'components/button';
import SiteCard from 'components/unit/site-card';
import UnitCard from 'components/unit/unit-card';
import SuccessOrderBox from 'components/success-order-box';
import { paperStyles } from 'components/paper';
import Notification from 'components/notification';
import Empty from 'components/empty';
import Loader from 'components/loader';
import store from 'store';
import * as actions from 'actions';


const cms = wurd.block('my-units');

const Parens = styled.span({
  '&::before': {
    content: '"("'
  },
  '&::after': {
    content: '")"'
  }
});

const Section = styled.section({
  '@media (min-width: 992px)': {
    ...paperStyles,
    overflow: 'hidden', // just to make borderRadius of children appear
    display: 'flex',
  },
});

const SectionTitle = styled.h4({
  '@media (max-width: 992px)': {
    padding: '1.25rem .5rem .25rem',
    fontSize: '.875rem!important',
    '.fs-5': { fontSize: '.75rem!important' }
  }
});

const SiteContainer = styled(SiteCard)({
  flex: 1,
  borderRadius: 0,
  border: 0,
  // borderBottom: ''var(--border-light)',
  '@media (min-width: 992px)': {
    borderBottom: 0,
    borderRight: 'var(--border-light)',
  },
});

const UnitsContainer = styled.div({
  flex: 2,
  'li > *': {
    borderBottom: 'var(--border-light)',
  },
  '@media (max-width: 576px)': {
    margin: '0 .5rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '.75rem',
    '> li > div': paperStyles,
  },
  '@media (min-width: 576px) and (max-width: 992px)': paperStyles,
});

const SubNav = styled.div({
  backgroundColor: '#fffc',
  zIndex: 2,
  borderTop: 'var(--border-light)',
  borderBottom: 'var(--border-light)',
  fontSize: '.875rem',
  whiteSpace: 'nowrap',
  overflowX: 'auto',
  '.title': {
    display: 'inline-block',
    padding: '1rem 5vw',
    borderRight: 'var(--border-light)',
    fontWeight: 600,
  },
  'strong': {
    fontWeight: 600,
  },
  'a:nth-of-type(2)': {
    marginLeft: '2vw',
  },
  a: {
    padding: '1rem 3vw'
  },
  '@media (min-width: 992px)': {
    marginBottom: '1.25rem',
  },
});

export default function MyUnits() {
  const { user } = store.get();
  const [modal, setModal] = useState();
  const navRef = useRef();
  const [curSite, setCurSite] = useState();
  const [sd, setSearchData] = useSearchData();
  const location = useLocation();

  const siteIds = [...new Set(user?.units?.map(u => u.siteId) || [])];
  const { isLoading, data: sites } = useQuery('sites-' + siteIds, () => actions.sites.fetch({ ids: siteIds, include: 'unitTypes' }), { enabled: !!user });

  function stickyNavScroll() {
    let cur;
    navRef.current.querySelectorAll('a').forEach(a => {
      const section = document.getElementById(a.hash.slice(1));
      if (!cur && section) {
        const rect = section.getBoundingClientRect();
        if (rect.bottom >= 300) {
          cur = a.hash.slice(7);
        }
      }
    });
    setCurSite(cur);
  }

  useEffect(() => {
    if (!user || !navRef.current) return;

    stickyNavScroll();
    document.addEventListener('scroll', stickyNavScroll, { passive: true });
    return () => document.removeEventListener('scroll', stickyNavScroll);
  }, [user]);

  useEffect(() => {
    const el = document.getElementById(location.hash.slice(1));
    if (el?.scrollIntoViewIfNeeded) {
      el.scrollIntoViewIfNeeded();
    }
  }, [user, location.hash]);

  if (user === undefined || isLoading) return <Loader />; // wait for load

  const unitsBySite = [
    ...user?.units?.reduce((m, unit) => {
      const site = sites.find(site => site.id == unit.siteId);
      if (!site) return m;
      if (unit.rental.activeJobs.some(job => job.type === 'unit_moveIn' && job.step === 'await_submitOrder')) return m;
      return m.set(site, [...m.get(site) || [], unit]);
    }, new Map()) || []
  ];


  if (user === null || unitsBySite.length === 0) {
    return (
      <Empty cms={cms.block('empty')} className="container mb-2">
        {new URLSearchParams(location.search).get('cancelled') !== null && (
          <cms.Markdown id="empty.cancelled" />
        )}
      </Empty>
    );
  }

  return (
    <>
      <SubNav ref={navRef}>
        <div className="container-xxl">
          <Link to="/sites" className="title text-decoration-none text-reset"><cms.Text id="locations" /></Link>
          {unitsBySite.map(([site, units]) => (
            <a href={`#${site.code}`} key={site.id} className={`text-decoration-none ${site.code === curSite ? 'text-primary' : 'text-muted'}`}>
              <strong>{getLangText(site.title)}</strong>
              <Parens className="ms-1">{units.length}</Parens>
            </a>
          ))}
        </div>
      </SubNav>

      <div className="container gx-0 gx-lg-2 pt-lg-4 pb-2 d-flex flex-column gap-5">
        {unitsBySite.map(([site, units]) => (
          <div key={site.id}>
            <h4 className="mb-4 d-none d-lg-block"><cms.Text id="subtitle" className="fs-5 fw-normal" />{cms.text('subtitle') && <span className="ms-1">{getLangText(site.title)}</span>}</h4>
            <Section key={site.id} id={site.code}>
              <SiteContainer site={site} className="site" showInfos={false} />

              <SectionTitle className="d-lg-none">
                <cms.Text id="subtitle" className="fs-5 fw-normal" />
                {cms.text('subtitle') && <span className="ms-1">{getLangText(site.title)}</span>}
              </SectionTitle>

              <UnitsContainer>
                <ul className="list-unstyled">
                  {units.map(unit => (
                    <li key={unit.id} id={`${site.code}-${unit.name}`}>
                      <UnitCard
                        unit={unit}
                        site={site}
                        unitType={site.unitTypes.find(type => type.id === unit.typeId)}
                        setModal={setModal}
                      />
                    </li>
                  ))}
                </ul>

                <div className="text-center">
                  <Button as={Link} to={`/sites/${site.code}`} className="my-2 mt-md-4" onClick={() => sessionStorage.removeItem('unit-order')}>
                    <cms.Text id="site.book" />
                  </Button>
                </div>

              </UnitsContainer>
            </Section>
          </div>
        ))}
      </div>

      <Notification show={!!sd.order} onClose={() => setSearchData({})}>
        {sd.order && (
          <SuccessOrderBox
            cms={wurd.block('unitOrder.success')}
            btnId="myUnits"
            btnLink={`/account/units#order_${sd.order}`}
          />
        )}
      </Notification>

      <Modal id="customFieldsModal" show={!!modal?.content} size={modal?.size} onHide={() => setModal()} dialogAs={modal?.dialogAs}>
        <button type="button" className="btn text-primary" data-bs-dismiss="modal" aria-label="Close" onClick={() => setModal()}><i className="text-white fal fa-times fa-2x" /></button>
        <div className="modal-body">
          {modal?.content}
        </div>
      </Modal>
    </>
  );
}
