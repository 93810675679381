import styled from '@emotion/styled';
import wurd, { WurdText } from 'wurd-react';
import { getLangText } from 'utils/ui';
import { getMapLink } from 'utils/site';
import Paper from 'components/paper';
import Markdown from 'components/markdown';
import FeatureList from 'components/feature-list';
import Accordion from 'components/accordion';
import useMobile from 'utils/useMobile';


const cms = wurd.block('siteDetail');

const Wrapper = styled(Paper)({
  border: 0,

  '.sgc-image': {
    borderRadius: 4,
    maxHeight: 400,
    aspectRatio: '7 / 4',
    width: '100%',
    objectFit: 'contain',
  },
});

export default function SiteDetail_SiteCard({
  site,
}) {
  const isMobile = useMobile();
  const allSiteFeatures = [...new Map(site.unitTypes.flatMap(u => u.tags.map(t => [t.id, t]))).values()];

  const content = (
    <Wrapper className="card">
      <div className="card-body">
        <img className={`sgc-image mb-3 mb-lg-4 ${site.imageFit || ''}`} src={site.image} alt={site.code} />

        {/*<small className="text-muted"><cms.Text id="selected" /></small>*/}

        <h2>{getLangText(site.title)}</h2>
        <p className="fw-light text-muted mt-3 fs-6">{getLangText(site.subtitle)}</p>

        {getLangText(site.info) && (
          <Markdown className="markdown text-muted mt-3 fs-6 fw-light">{getLangText(site.info)}</Markdown>
        )}

        <ul className="list-group list-group-flush mb-4">
          {site.phone && (
            <li className="list-group-item ps-0">
              <i className="fal fa-phone-alt fa-fw fs-6" />
              <a className="ms-2 text-reset fw-500 fs-6 text-decoration-none" href={`tel:${site.phone.replace(/\s/g, '')}`} target="_blank">{site.phone}</a>
            </li>
          )}
          <li className="list-group-item ps-0">
            <i className="fal fa-envelope fa-fw fs-6" />
            <a className="ms-2 text-reset fw-500 fs-6 text-decoration-none" href={`mailto:${site.email}`} target="_blank">{site.email}</a>
          </li>
          <li className="list-group-item ps-0">
            <i className="fal fa-map-marker-alt fa-fw fs-6" />
            <a className="ms-2 fs-6 text-reset text-decoration-none" href={getMapLink(site)} target="_blank">{getLangText(site.address)}</a>
          </li>
        </ul>

        {getLangText(site.hours) && (
          <div className="mb-4">
            <h4 className="fs-6 fw-600"><cms.Text id="hours" /></h4>
            <Markdown className="text-muted fs-6">{getLangText(site.hours)}</Markdown>
          </div>
        )}

        {allSiteFeatures.length > 0 && (
          <div>
            <h4 className="fs-6 fw-600"><cms.Text id="features" /></h4>
            <FeatureList tags={allSiteFeatures} />
          </div>
        )}
      </div>
    </Wrapper>
  );

  return isMobile
    ? (
      <Accordion
        id="siteDetail"
        variant="white"
        title={(
          <span className="d-flex flex-column flex-1 gap-1">
            <small style={{ opacity: .7, color: 'var(--bs-gray)' }}><WurdText id="unitOrder.summary.site.done" /></small>
            <span>{getLangText(site.title)}</span>
          </span>
        )}
      >
        {content}
      </Accordion>
    ) : content;
}
