import { Fragment } from 'react';
import styled from '@emotion/styled';
import wurd from 'wurd-react';

import store from 'store';
import { getLangText, getPrice } from 'utils/ui';
import { typePrice } from 'utils/site';
import useMobile from 'utils/useMobile';
import Dl from 'components/dl';


const cms = wurd.block('unitOrder.summary');

export const Accordion = styled.div({
  position: 'sticky',
  top: 58,
  zIndex: 3,
  width: 'min(564px, 100%)',
  '.accordion-item': {
    border: 'var(--border-light)!important',
    '@media only screen and (max-width: 564px)': {
      borderTop: 0,
      borderLeft: 0,
      borderRight: 0,
      borderBottomWidth: 1,
    }
  },
  '@media only screen and (min-width: 992px)': {
    width: 400,
    marginTop: '2rem',
    borderRadius: 4,
    boxShadow: 'var(--shadow-light)',
    overflow: 'hidden',
    '.accordion-collapse': {
      paddingTop: '2rem!important'
    },
  },
  hr: {
    opacity: 1,
  }
});

const UnitTypeCard = styled.div({
  borderTop: '1px solid #aaa4',
  borderBottom: '1px solid #aaa4',
  padding: '.5rem 0',
  display: 'flex',
  gap: '.5rem',
  margin: '.5rem 0',
  alignItems: 'center',

  img: {
    height: 70
  },
  '.padded': {
    paddingTop: '1.15rem'
  }
});

export const CollapsibleButton = props => <button {...props} className="accordion-button collapsed d-flex" type="button" data-bs-toggle="collapse" data-bs-target="#orderSummmary-details" aria-expanded="false"
  aria-controls="orderSummmary-details" />;


export default function SummaryPreview({ site, unitType, data, date, className }) {
  const { invoicePeriod } = store.get('settings');
  const isMobile = useMobile();
  const deposit = unitType.deposit_display || unitType.deposit || 0;
  const rent = typePrice(unitType) || 0;

  const productsCharges = Object.entries(data.products || {})
    .map(([id, qty]) => {
      if (qty === 0) return null;
      const prod = site.products.find(prod => prod.id === id);
      if (!prod) return null; // happens when data where loading from localStorage from another site order, todo better fix
      return { ...prod, qty, amount: qty * prod.price };
    })
    .filter(Boolean);

  const allCharges = [...unitType.charges, ...productsCharges];

  const chargesTotal = allCharges.reduce((memo, charge) => memo + Number(charge.amount), 0);

  const invoiceTotal = deposit + rent + chargesTotal;

  const Button = isMobile ? CollapsibleButton : 'span';

  return (
    <Accordion className={`accordion accordion-flush mx-auto ${className || ''}`} id="orderSummmary">
      <div className="accordion-item">
        <div
          id="orderSummmary-details"
          className={`accordion-collapse p-4 collapse ${isMobile ? '' : 'show'}`}
          aria-labelledby="orderSummmary-title"
          data-bs-parent="#orderSummmary"
        >
          <h3><cms.Text id="title" /></h3>
          <hr />
          <div className="lh-sm"><small className="fs-7 text-muted"><cms.Text id="location" /></small></div>
          <div><strong className="fs-5 me-2">{getLangText(site.title)}</strong></div>
          <UnitTypeCard>
            <img src={unitType.image} alt={unitType.code} />
            <div className="flex-grow-1 d-flex flex-column">
              <small className="fs-7 text-muted"><cms.Text id="type" /></small>
              <strong className="fs-4">{getLangText(unitType.title)}</strong>
              {getLangText(unitType.subtitle) && <small>({getLangText(unitType.subtitle)})</small>}
            </div>
            <div className={`d-flex flex-column align-items-end ${cms.text('type') ? 'padded' : ''}`}>
              <strong className="fs-4 ls-2">{getPrice(rent)}</strong>
              <cms.Text id={`per_${invoicePeriod}`} type="small" />
            </div>
          </UnitTypeCard>
          <h4 className="fs-5 mt-4 pt-2"><cms.Text id="details" type="strong" /></h4>
          <hr />
          <Dl>
            <dt><cms.Text id="date" /></dt><dd><time dateTime={date}>{date}</time></dd>
            <dt><cms.Text id="monthlyRent" /></dt><dd>{getPrice(rent)}<cms.Text id={`per_${invoicePeriod}`} type="small" /></dd>
            <dt><cms.Text id="securityDeposit" /></dt><dd>{getPrice(deposit)}</dd>
            {data.prepay && <><dt><cms.Text id="prepay" /></dt><dd>{data.prepay}<cms.Text id={data.prepay > 1 ? 'months' : 'month'} type="small" /></dd></>}
            {data.code && <><dt><cms.Text id="promoCode" /></dt><dd>{data.code}</dd></>}
            {allCharges.map(charge => (
              <Fragment key={charge.id}>
                <dt>{`${getLangText(charge.title)}${charge.qty > 1 ? ` (x${charge.qty})` : ''}`}</dt>
                <dd>{getPrice(charge.amount)}{charge.type === 'recurring' && <cms.Text id={`per_${invoicePeriod}`} type="small" />}</dd>
              </Fragment>
            ))}
          </Dl>
        </div>
        <h2 className="accordion-header" id="orderSummmary-title">
          <Button className="accordion-button d-flex">
            <span className="d-flex flex-1">
              <strong className="flex-1 fw-600"><cms.Text id="total" /></strong>
              <strong className="fw-600">{getPrice(invoiceTotal)}</strong>
            </span>
          </Button>
        </h2>
      </div>
    </Accordion>
  );
}
