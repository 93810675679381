import { useParams } from 'react-router-dom';
import wurd from 'wurd-react';
import { useQuery } from 'react-query';
import Split from 'components/split-page';
import Progress from 'components/unit-progress';
import SiteCard from 'components/site-detail/site-card';
import UnitTypeList from 'components/site-detail/unit-type-list';
import Title from 'components/title';
import Loader from 'components/loader';
import * as actions from 'actions';


const cms = wurd.block('siteDetail');

export default function SiteDetail() {
  const { site: siteCode } = useParams();

  const { isLoading, error, data: site } = useQuery('site-' + siteCode, () => actions.sites.get(siteCode, { include: 'unitTypes' }));

  if (isLoading) return <Loader />;

  if (!site) return <Title cms={cms.block('notFound')} className="mt-5 text-center" />;

  return (
    <>
      <Progress site={site} />

      <Split className="mb-2">
        <div className="flex-1"><SiteCard site={site} showFeatures /></div>
        <div className="flex-1 px-2 px-sm-0 mt-lg-2"><UnitTypeList site={site} /></div>
      </Split>
    </>
  );
}
