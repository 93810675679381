import wurd from 'wurd-react';
import Block from 'components/block';
import Paper from 'components/paper';
import Title from 'components/title';
import Button from 'components/button';


export default ({ cms, className, children }) => (
  <Block className={className}>
    <Title cms={cms.block('title')} />

    <Paper padded className="mt-5">
      {!wurd.editMode && !cms.text('info') ? null : <cms.Markdown id="info" type="div" />}

      {/*{cms.editMode && <cms.Text id="action.url" />}
      {cms.editMode && <cms.Text id="action.icon" />}
      {cms.text('action.url') && (
        <div className="mt-5 text-center">
          <Button as={Link} to={cms.text('action.url')}>
            {cms.text('action.icon') && <i className={`${cms.text('action.icon')} me-1`} />}
            <cms.Text id="action.text" />
          </Button>
        </div>
      )}*/}

      {children}
    </Paper>
  </Block>
);